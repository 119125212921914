import React from 'react'

type Props = {}

const SearchFlights = (props: Props) => {
  return (
    <>SearchFlightsWidget (NOT READY)</>
  )
}

export default SearchFlights